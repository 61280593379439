@font-face {
    font-family: 'Hind';
    src: local('Hind'),
        url('./assets/fonts/hind/Hind-Medium.ttf') format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'HindBold';
    src: local('Hind Bold'), local('Hind-Bold'),
        url('./assets/fonts/hind/Hind-Bold.ttf') format('truetype');
    font-weight: bold;
}
@font-face {
    font-family: 'Playfair';
    src: local('Playfair Display'), local('Playfair-Display'),
        url('./assets/fonts/playFair/PlayfairDisplay-Regular.ttf')
            format('truetype');
    font-weight: normal;
}

body {
    font-size: 14px;
    line-height: 24px;
    line-height: 1.5;
    margin: 0 auto;
    background-color: #ffffff;
    color: #949ea8;
    font-family: Hind;
}

h1 {
    padding: 0;
    margin: 0;
    color: #323c46;
    line-height: 48px;
    font-size: 28px;
    font-style: normal;
    font-weight: normal;
    display: flex;
    align-items: center;
}

strong {
    color: #323c46;
}

button:focus {
    outline: none;
}
a:link,
a:visited,
a:hover,
a:active {
    padding: 0;
    border: none;
    background: none;
    text-decoration: none;
    color: #949ea8;
    font-weight: bold;
    text-transform: uppercase;
}

.logo {
    position: absolute;
    width: 28.42px;
    height: 27.21px;
    left: 61.08px;
    top: 39.06px;
    z-index: 1;
}

/* #region header */
.header {
    line-height: 24px;
    position: absolute;
    width: 100%;
    text-align: right;
    padding: 40px 39px;
    box-sizing: border-box;
    z-index: 1;
}

.sign-up-question {
    color: #c9ced3;
}
/* #endregion */

.left-panel-layout-body {
    display: flex;
}

/* #region left-panel */

.left-panel-body {
    position: relative;
    float: left;
    flex: 1;
    height: 100%;
}

.left-panel-background-image {
    position: absolute;
    width: 683px;
    height: 1025px;
    left: -83px;
    top: -1px;

    mix-blend-mode: normal;
    opacity: 0.11;
}

.left-panel-mask {
    position: relative;
    max-width: 600px;
    height: 100vh;
    min-height: 1025px;

    background: #323c46;
}

.left-panel-quote {
    position: absolute;
    width: 100%;
    max-width: 600px;
    top: 780px;
}
.left-panel-title {
    margin: 0 auto;
    text-align: center;
    width: 310px;
    padding-top: 0;

    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    display: flex;
    align-items: flex-end;
    text-align: center;

    color: #ffffff;
}

.left-panel-rectangle {
    margin: 0 auto;
    width: 12px;
    height: 2px;
    margin-top: 11px;
    /* top: 897px; */

    background: #1be38b;
}

.left-panel-han-solo {
    margin: 0 auto;
    position: relative;
    width: 69px;
    margin-top: 15px;

    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #949ea8;
}

/* #endregion */

/* #region right-panel */
.right-panel-body {
    position: relative;
    flex: 2;
    width: 100%;
    background-color: #ffffff;
}

/* #endregion */

/* #region content */
.content-container {
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
}
.content {
    margin: 0 auto;
    line-height: 24px;
    box-sizing: border-box;
    z-index: 1;
}
.input-error,
.message {
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding-top: 6px;
}

.info-color {
    color: #949ea8;
}
.input-error,
.error-color {
    color: #fc226e;
}
.input.input-error-color {
    border-bottom: 1px solid #ff4081;
}
.input-error {
    width: 416px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
}

.form-field {
    display: flex;
    width: 480px;
    flex-flow: column-reverse;
    padding-top: 24px;
    margin-bottom: 2px;
    height: 48px;
}

.label,
.input {
    transition: all 0.1s;
    touch-action: manipulation;
}

.input {
    font-size: 18px;
    line-height: 24px;
    border: 0;
    border-bottom: 1px solid #d2d6da;
    -webkit-appearance: none;
    border-radius: 0;
    margin-bottom: 8px;
    cursor: text;
    color: #323c46;
}

.input:focus {
    outline: 0;
    border-bottom: 1px solid #323c46;
}

.label {
    width: 373.33px; /* 18/14 = 1.285714 => 480 / 1.285714 = 373.333 = width */
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    margin: -8px 0 0 0;
    color: #c9ced3;
}

.input:placeholder-shown + .label {
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 24px) scale(1.285714); /* 18/14 = 1.285714 */
}

::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
}

.input:focus::-webkit-input-placeholder {
    opacity: 1;
}

.input:not(:placeholder-shown) + .label,
.input:focus + .label {
    transform: translate(0, 0) scale(1);
    cursor: text;
}
.password-eye {
    width: 24px;
    height: 24px;
    margin: 0 0 -35px 456px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.button-dark,
.button-submit,
.button {
    width: 240px;
    height: 57px;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1px;
    align-items: center;
    text-align: center;
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    border-width: 0;
    background-color: #22d486;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-dark,
.button-submit {
    margin-top: 40px;
}
.button-dark:hover,
.button-submit:hover,
.button:hover {
    cursor: pointer;
}

.button-submit:hover,
.button:hover {
    background-color: #20bd78;
}

.button-plus,
.button-dark {
    background-color: #323c46;
}

.button-plus:hover,
.button-dark:hover {
    background-color: #565d5a;
}

.button-loading-mask {
    width: 27px;
    height: 27px;
    background: conic-gradient(
        from 90deg at 50% 50%,
        rgba(255, 255, 255, 0.187047) -23.59deg,
        #ffffff 10.06deg,
        rgba(255, 255, 255, 0.187047) 336.41deg,
        #ffffff 370.06deg
    );
    mix-blend-mode: normal;
}

.button-loading {
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
}

.button-edit,
.button.button-disabled {
    color: #a9aeb4;
}

.button-join,
.button-leave,
.button-edit {
    font-family: Hind;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    width: 100px;
    height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    padding: 2px 0 0 0;
}

.button-join,
.button-leave {
    color: #ffffff;
}

.button.button-disabled,
.button-edit {
    background-color: #d9dce1;
}
.button.button-disabled:hover,
.button-edit:hover {
    background-color: #c4c9d1;
}
.button-join {
    background-color: #22d486;
}
.button-join:hover {
    background-color: #20bd78;
}

.button-leave {
    background-color: #ff4081;
}

.button-leave:hover {
    background-color: #e73370;
}

.button-plus {
    position: absolute;
    position: fixed;
    bottom: 32px;
    right: 32px;
    width: 56px;
    height: 56px;
    font-size: 14px;
    line-height: 14px;
    box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    z-index: 5;
}

.link-button,
.link-button-selected {
    font-family: Hind;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.link-button-close,
.link-button-tab,
.link-button-switch,
.link-button-link {
    cursor: pointer;
}
.link-button-tab,
.link-button-link {
    color: #a9aeb4;
}

.link-button-tab,
.link-button-tab-selected {
    padding: 0 32px 0 0;
}

.link-button-switch,
.link-button-switch-selected {
    margin: 0 0 0 8px;
    line-height: 0px;
}

.link-button-switch {
    filter: invert(95%) sepia(8%) saturate(115%) hue-rotate(178deg)
        brightness(92%) contrast(93%);
}

.link-button-tab-selected,
.link-button-switch-selected,
.link-button-link-selected {
    cursor: text;
}
.link-button-close,
.link-button-tab-selected,
.link-button-link-selected {
    color: #323c46;
}

.link-button-switch-selected {
    filter: invert(19%) sepia(4%) saturate(3620%) hue-rotate(170deg)
        brightness(92%) contrast(83%);
}

/* #endregion */

.page-404-background-image {
    position: absolute;
    width: calc(100% + 138.53px);
    height: calc(100% - 296.1px);
    left: -138.53px;
    top: 296.1px;
    background-repeat: no-repeat;
    z-index: 0;
}

/* #region Dashboard panel */
.basic-layout-header {
    height: 100px;
    line-height: 24px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 39px 31px 43px;
    box-sizing: border-box;
    z-index: 1;
}

.basic-layout-logo {
    position: relative;
    float: left;
    width: 28.42px;
    height: 27.21px;
    filter: brightness(0%) saturate(100%) invert(18%) sepia(7%) saturate(2100%)
        hue-rotate(169deg) brightness(92%) contrast(81%);
}

.basic-layout-right-component {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}

.basic-layout-content-container {
    background-color: #f9f9fb;
    display: flex;
    padding: 169px 7.8125% 96px 7.8125%; /* 112.5/1440 = 0.078125 */
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
}

.filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.switcher {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
/* #endregion */

/* #region event list */
.event-list-list,
.event-list-grid {
    display: flex;
    /* flex-grow: 1; */
    justify-content: space-between;
    line-height: 24px;
}
.event-list-list {
    width: calc(100% - 15px);
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}
.event-list-grid {
    align-items: flex-start;
    margin: 0 -7.5px;
    width: 100%;
    flex-wrap: wrap;
}

.dashboard-panel {
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7.5px 39px 7.5px;
}
/* #endregion */

/* #region event card grid */
.event-card-grid {
    height: 232px; /* 296 - 64 = 232px = */
    margin: 0 7.5px 16px 7.5px;
    padding: 32px;
    width: 26.83%;
    flex: 1 1 26.83%; /* 390 - 64 = 326px => 326/1215 = 26,83% for EXACT UI*/
    background: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.108696);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}
.invisible-event-card {
    margin: 0 7.5px 0px 7.5px;
    padding: 0 32px;
    flex: 1 1 26.83%;
}

.event-card-datetime-grid {
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    color: #cacdd0;
}

.event-card-title-grid {
    padding-top: 10px;
    margin: 0;
    color: #323c46;
    line-height: 48px;
    font-size: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-card-owner-grid {
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #7d7878;
    margin-top: -10px;
    padding-bottom: 32px;
}
.event-card-description-grid {
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

    color: #949ea8;
}
.event-card-bottom-panel-grid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
    align-self: flex-end;
}
.event-card-bottom-panel-attendees-grid {
    position: relative;
    float: left;
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #949ea8;
}
.event-card-bottom-panel-attendees-icon-grid {
    padding-right: 4px;
}
.event-card-bottom-panel-button-grid {
    float: right;
}

/* #endregion */

/* #region event card list */
.event-card-list {
    width: 100%;
    height: 64px;
    margin: 0 -7.5px 16px -7.5px;
    padding: 13px 0px 11px 0px;

    flex: 1;
    background: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.108696);
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.event-card-list :nth-child(1) {
    order: 4;
}
.event-card-list :nth-child(2) {
    order: 1;
}
.event-card-list :nth-child(3) {
    order: 3;
}
.event-card-list :nth-child(4) {
    order: 2;
}
.event-card-list :nth-child(5) {
    order: 5;
}

.event-card-datetime-list {
    width: 200px; /* 138px by description, but it doesn't work for for example December 2digit date and 2digit hour*/
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    padding: 0 20px;

    color: #cacdd0;
}

.event-card-title-list {
    width: 255px;
    padding: 0 20px 0 32px;
    color: #323c46;
    line-height: 48px;
    font-size: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-card-owner-list {
    width: 101px;
    padding: 0 20px;
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    color: #7d7878;
}
.event-card-description-list {
    width: 240px;
    padding: 0 20px;
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;

    color: #949ea8;
}
.event-card-bottom-panel-list {
    width: 222px; /* according specification 280px, but I extended date and shorted thisone */
    padding-right: 32px;
}
.event-card-bottom-panel-attendees-list {
    position: relative;
    float: left;
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #949ea8;
}
.event-card-bottom-panel-button-list {
    float: right;
}
.event-card-bottom-panel-button-list .button {
    width: 100px;
    height: 32px;
}

/* #endregion */

/* #region loading */
.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -31px;
    margin-left: -31px;
}
.loading {
    width: 63px;
    height: 63px;
    background-repeat: no-repeat;
}

.loading-mask {
    width: 63px;
    height: 63px;
    background: conic-gradient(
        from 90deg at 50% 50%,
        rgba(255, 255, 255, 0.187047) -23.59deg,
        rgba(0, 0, 0, 0.244084) 6.81deg,
        rgba(255, 255, 255, 0.187047) 336.41deg,
        rgba(0, 0, 0, 0.244084) 366.81deg
    );
    mix-blend-mode: normal;
}
/* #endregion */

/* #region create event */
.create-event-card {
    padding: 41px 32px 39px 32px;
    flex: 1 1 416px; /* 480 - 2*32px = 416px*/
    background: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.108696);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.create-event-card h1 {
    justify-content: center;
}

.create-event-card .form {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.create-event-card .form .input-error {
    align-self: self-start;
}
.create-event-card .form .form-field {
    width: 416px;
}
.create-event-card .form .button-submit {
    margin-top: 22px;
}
.create-event-card .form .label {
    padding-bottom: 8px;
}

.create-event-card .message {
    display: flex;
    margin-top: -2px;
    justify-content: center;
}

/* #endregion */

/* #region close container */
.close-container {
    display: flex;
    align-items: center;
}

.link-button-close {
    text-transform: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 48px;
}

.link-button-close:hover {
    color: #565d5a;
}

.close-icon-mask-hover,
.close-icon-mask {
    width: 24px;
    height: 24px;
    background: #323c46;
    mix-blend-mode: normal;
}
.close-icon-mask {
    background: #323c46;
}
.close-icon-mask-hover,
.close-icon-mask:hover {
    background: #565d5a;
}

.close-icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
}

/* #endregion */

/* #region dropdown */

.dropdown-select:link,
.dropdown-select:visited,
.dropdown-select:active,
.dropdown-select:hover,
select:link,
select:visited,
select:hover,
select:active {
    padding: 0;
    border: none;
    background: none;
    text-decoration: none;
    color: #323c46;
    font-weight: bold;
    text-transform: uppercase;
}
.dropdown-label {
    font-family: Hind;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #a9aeb4;
    /* identical to box height, or 200% */

    letter-spacing: 1px;
    padding: 0 5px 0 16px;
    text-transform: uppercase;
    color: #a9aeb4;
}
.profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    color: #949ea8;
    background: #d9dce1;
    text-align: center;
    padding: 0 0;
    letter-spacing: 0;
}
.dropdown-label-profile {
    letter-spacing: 0;
    padding-right: 8px;
}
.dropdown {
    position: relative;
    width: 100%;
    cursor: pointer;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
}

.dropdown::after {
    position: relative;
    content: '';
    width: 10px;
    height: 5px;
    background-color: #323c46;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
}

.dropdown-profile::after {
    background-color: #d9dce1;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
}

.dropdown-select {
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    width: 100%;
    cursor: inherit;
    outline: none;
    font-family: Hind;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: none;

    color: #323c46;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.dropdown-select,
.dropdown::after {
    grid-area: select;
}
.dropdown-select-profile:link,
.dropdown-select-profile:visited,
.dropdown-select-profile:active,
.dropdown-select-profile:hover,
.dropdown-select-profile {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-right: 18px; /* 10px for triangle, 8px padding */
    color: #949ea8;
    text-transform: none;
}

.dropdown-option {
    font-family: Hind;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 16px;
    background-color: #fff;
    border-radius: 14px;
    border: none;
    color: #9ca5af;
}
/* #endregion */

/* #region mobile design */
@media only screen and (max-width: 600px) {
    .logo {
        position: absolute;
        width: 27px;
        height: 38px;
        left: 24px;
        top: 24px;
        z-index: 1;
    }

    .content-container {
        padding-top: 114px;
        justify-content: flex-start;
    }

    h1 {
        font-size: 22px;
        justify-content: center;
    }

    .input-error,
    .message {
        font-size: 14px;
        margin-top: -2px;
        padding-top: 0px;
        display: flex;
        justify-content: center;
    }

    .message,
    .form-field,
    .input-error {
        max-width: 272px;
    }

    .label {
        width: 211.5556px; /* 18/14 = 1.285714 => 272 / 1.285714 = 211.5556 = width */
    }

    .form {
        padding-top: 7px;
    }

    .password-eye {
        margin: 0 0 -35px 248px;
    }
    .button-dark,
    .button-submit,
    .button {
        align-self: center;
    }

    .sign-up-link {
        padding-top: 16px;
        display: flex;
        justify-content: center;
    }
    .sign-up-question {
        padding-right: 5px;
    }

    .basic-layout-header {
        height: 80px;
        padding: 24px 24px 24px 24px;
    }
    .basic-layout-logo {
        position: relative;
        float: left;
        width: 23px;
        height: 22px;
        filter: brightness(0%) saturate(100%) invert(18%) sepia(7%)
            saturate(2100%) hue-rotate(169deg) brightness(92%) contrast(81%);
    }

    .basic-layout-content-container {
        padding: 80px 0.5px 96px 0.5px;
    }

    .event-list-grid {
        margin: 0;
    }

    .dashboard-panel {
        padding: 41px 7.5px 31px 7.5px;
    }

    .event-list-list .dashboard-panel {
        padding: 41px 0 31px 0px;
        margin: 0 0 0 15px;
    }

    .switcher {
        padding-right: 13px;
    }

    /* #region event card grid */
    .event-card-grid {
        width: calc(100% - 60px); /* 60px is margin (2*8) + padding (2*24)*/
        min-width: 260px;
        height: 248px; /* 296 - 48 = 248px = */
        padding: 24px;
        flex: 1 1; /* 390 - 64 = 326px => 326/1215 = 26,83% for EXACT UI*/
    }

    .event-card-owner-grid {
        margin-top: -5px;
    }

    /* #endregion */

    /* #region event card list */
    .event-card-list {
        width: 100%;
        height: 119px;
        margin: 0 -15px 16px 0px;
        padding: 3px 0px 24px 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .event-card-list :nth-child(1) {
        order: 4;
    }
    .event-card-list :nth-child(2) {
        order: 1;
    }
    .event-card-list :nth-child(3) {
        order: 3;
    }
    .event-card-list :nth-child(4) {
        order: 2;
    }
    .event-card-list :nth-child(5) {
        order: 5;
    }

    .event-card-datetime-list {
        padding: 8px 0 0 16px;
    }

    .event-card-title-list {
        width: 271px;
        font-size: 18px;
        padding: 0 17px 0 16px;
    }

    .event-card-owner-list {
        display: none;
    }
    .event-card-description-list {
        width: calc(100% - 33px); /* 33px is padding (17 + 16)*/
        margin-top: -11px;
        padding: 0 17px 0 16px;
        flex-grow: 0;
    }

    .event-card-bottom-panel-list {
        /* width: 271px;
        padding: 0 17px 0 16px; */

        width: calc(100% - 33px);
        padding: 0 17px 0 16px;
        display: flex;
        flex-direction: row-reverse;
        align-content: space-between;
    }

    .event-card-bottom-panel-attendees-list {
        display: flex;
        justify-self: flex-start;
        align-items: center;
        width: 100%;
    }

    .event-card-bottom-panel-button-list {
        align-items: flex-end;
    }
    /* #endregion */

    .button-plus {
        bottom: 24px;
        right: 16px;
    }

    .create-event-card {
        padding: 18px 16px 24px 16px;
        flex: 1;
    }

    .close-text {
        display: none;
    }

    .close-icon {
        height: 14px;
        width: 14px;
    }
    .close-icon-mask {
        height: 14px;
        width: 14px;
    }

    .form-field {
        padding-top: 9px;
    }

    .create-event-card .form {
        padding-top: 6px;
    }

    .dropdown-label {
        font-size: 12px;
    }

    .dropdown-label-profile {
        padding-right: 0px;
    }

    .dropdown-select-profile {
        width: 0px;
    }
}
/* #endregion */
